// client/src/pages/ClientDashboardPage.js

import React from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import BookIcon from '@mui/icons-material/Book';
import HorseIcon from '@mui/icons-material/EmojiNature';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    flexGrow: 1,
    minHeight: '100vh',
    backgroundImage: 'url(/images/horsesclub.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: 50,
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.3)', // Dark overlay
      zIndex: 1,
    },
  },
  content: {
    position: 'relative',
    zIndex: 2, // Ensure content is above the overlay
  },
  icon: {
    fontSize: '4rem',
    color: theme.palette.primary.main,
  },
  title: {
    marginTop: theme.spacing(2),
  },
  card: {
    height: '100%',
  },
}));

function ClientDashboardPage({ logout }) {
  const classes = useStyles();

  const clientOptions = [
    {
      title: 'Make a Reservation',
      icon: '📖', // replace with actual icon if desired
      link: '/reserve',
    },
    {
      title: 'Show All Horses',
      icon: '🐎', // replace with actual icon if desired
      link: '/show-horses',
    },
    {
      title: 'Show My History',
      icon: '📜', // replace with an actual icon if desired
      link: '/my-history', // route to the new history page
    },
  ];

  return (
    <div className={classes.root}>
      <Container maxWidth="md" className={classes.content}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography
            variant="h4"
            gutterBottom
            style={{ color: '#fff', fontWeight: 'bold' }}
          >
            Client Dashboard
          </Typography>
          <Button variant="contained" color="secondary" onClick={logout}>
            Logout
          </Button>
        </div>
        <Grid container spacing={4}>
          {clientOptions.map((option) => (
            <Grid item xs={12} sm={6} md={6} key={option.title}>
              <Card className={classes.card}>
                <CardActionArea component={Link} to={option.link}>
                  <CardContent style={{ textAlign: 'center' }}>
                    {option.icon}
                    <Typography variant="h6" className={classes.title}>
                      {option.title}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
}

export default ClientDashboardPage;
 
// client/src/services/reservationService.js
import api from './api';

export const getHorses = () => {
  return api.get('/user/horses');
};


export const getAvailableSlots = (horseId, date) => {
  return api.get(`/reservations/available-slots`, {
    params: { horseId, date },
  });
};

// export const getAvailableSlots = (date) => {
//   return api.get(`/reservations/available-slots?date=${date}`);
// };

export const makeReservation = (reservationData) => {
  return api.post('/reservations/reserve', reservationData);
};

export const getBalance = () => {
  return api.get('/user/balance');
};


export const getHistory = () => {
  return api.get('/user/history');
};


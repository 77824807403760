// client/src/App.js


import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { jwtDecode } from "jwt-decode";

import ClientDashboardPage from "./pages/ClientDashboardPage";
import ReservationPage from "./pages/ReservationPage";
import LoginPage from "./pages/LoginPage";
import ShowHorsesPage from "./pages/ShowHorsesPage";

import ReservationFlow from './pages/ReservationFlow'; // Ensure correct path
import ConfirmationPage from './pages/ConfirmationPage'; // If used elsewhere
import ClientHistoryPage from "./pages/ClientHistoryPage";


import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline, GlobalStyles } from "@mui/material";

//  require('dotenv').config();

function App() {
  const [token, setToken] = useState(localStorage.getItem("token") || "");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const saveToken = (userToken) => {
    localStorage.setItem("token", userToken);
    setToken(userToken);
  };

  const logout = () => {
    localStorage.removeItem("token");
    setToken("");
    setIsAuthenticated(false);
  };

  useEffect(() => {
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        // Check if token is expired
        if (decodedToken.exp * 1000 < Date.now()) {
          // Token has expired
          logout();
        } else {
          setIsAuthenticated(true);
        }
      } catch (err) {
        // Invalid token
        logout();
      }
    } else {
      setIsAuthenticated(false);
    }
  }, [token]);

  // const theme = createTheme({
  //   palette: {
  //     primary: {
  //       main: '#3f51b5', // Customize your primary color
  //     },
  //   },
  // });

  const theme = createTheme({
    palette: {
      type: "dark", // Use dark mode
      primary: {
        main: "#888", // Customize your primary color
      },
      background: {
        default: "888",
        // Background color for the app
      },
    },
    typography: {
      fontFamily: "Roboto, sans-serif",
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          body: {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            background: "url(/images/horsesclub.png)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "center",
            backgroundPosition: "center",
          },
        }}
      />
      <Router>
        {isAuthenticated ? (
         <Routes>
         <Route path="/" element={<ClientDashboardPage logout={logout} />} />
         <Route path="/make-reservation" element={<ReservationPage />} />
         <Route path="/show-horses" element={<ShowHorsesPage />} />
         <Route path="/reserve/*" element={<ReservationFlow />} />
         <Route path="/confirmation" element={<ConfirmationPage />} />
         <Route path="/my-history" element={<ClientHistoryPage />} />

         <Route path="*" element={<Navigate to="/" />} />
       </Routes>
     ) : (
       <Routes>
         <Route path="/" element={<LoginPage setToken={saveToken} />} />
         <Route path="*" element={<Navigate to="/" />} />
       </Routes>
        )}
      </Router>
    </ThemeProvider>
  );
}

export default App;




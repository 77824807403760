




// // client/src/pages/ReservationPage.js

// import React, { useState, useEffect } from 'react';
// import { getAvailableSlots, makeReservation, getBalance } from '../services/reservationService';
// import 'react-calendar/dist/Calendar.css';
// import {
//   Container,
//   Typography,
//   List,
//   ListItem,
//   ListItemText,
//   Button,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Paper,
// } from '@mui/material';
// import Calendar from 'react-calendar';

// function ReservationPage({ logout }) {
//   const [date, setDate] = useState(new Date());
//   const [slots, setSlots] = useState([]);
//   const [selectedSlot, setSelectedSlot] = useState(null);
//   const [availableHorses, setAvailableHorses] = useState([]);
//   const [selectedHorse, setSelectedHorse] = useState('');
//   const [selectedHorseCost, setSelectedHorseCost] = useState(0);
//   const [dialogOpen, setDialogOpen] = useState(false);
//   const [balance, setBalance] = useState(0);

//   useEffect(() => {
//     fetchAvailableSlots(date);
//     fetchBalance();
//   }, [date]);

//   const fetchAvailableSlots = async (selectedDate) => {
//     try {
//       const response = await getAvailableSlots(selectedDate.toISOString().split('T')[0]);
//       const slotsWithTime = response.data.map(slot => ({
//         ...slot,
//         time: slot.time || slot.slotTime, // Adjust property name if necessary
//       }));
//       setSlots(slotsWithTime);
//     } catch (err) {
//       alert(err);
//       console.error(err);
//     }
//   };
  

//   const fetchBalance = async () => {
//     try {
//       const response = await getBalance();
//       setBalance(response.data.balance);
//     } catch (err) {
//       console.error(err);
//     }
//   };

//   const handleDateChange = (selectedDate) => {
//     setDate(selectedDate);
//   };

//   const handleSlotClick = (slot) => {
//     if (slot.availableHorses.length === 0) {
//       alert('No horses available at this time slot');
//       return;
//     }
//     setSelectedSlot(slot);
//     setAvailableHorses(slot.availableHorses);
//     setSelectedHorse('');
//     setSelectedHorseCost(0);
//     setDialogOpen(true);
//   };

//   const handleHorseChange = (e) => {
//     const horseId = e.target.value;
//     setSelectedHorse(horseId);
//     const horse = availableHorses.find((h) => h.HorseID === horseId);
//     setSelectedHorseCost(horse ? horse.Cost : 0);
//   };

//   const handleReserve = async () => {
//     if (!selectedHorse) {
//       alert('Please select a horse');
//       return;
//     }

//     // Check if balance is sufficient
//     if (balance < selectedHorseCost) {
//       alert('Insufficient balance to make this reservation');
//       return;
//     }

//     try {
//       await makeReservation({
//         date: date.toISOString().split('T')[0],
//         time: selectedSlot.time,
//         horseId: selectedHorse,
//       });
//       alert('Reservation made successfully');
//       setDialogOpen(false);
//       fetchAvailableSlots(date); // Refresh slots
//       fetchBalance(); // Update balance
//     } catch (err) {
//       alert(err.response?.data || 'Error making reservation');
//       console.error(err);
//     }
//   };

//   const formatTime = (timeString) => {
    
//     try{
//       if (!timeString) {
//         return ''; // Return an empty string or a default value if timeString is undefined
//       }
//       const [hour, minute] = timeString.split(':');
//       const date = new Date();
//       date.setHours(parseInt(hour), parseInt(minute));
//       return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
//     } catch(err){
//       alert(err);
//     }
  
//   };

//   return (
//     <Container>
//       <Paper elevation={3} sx={{ mt: 8, p: 4 }}>
//         <Typography variant="h5" gutterBottom>
//           Make a Reservation
//         </Typography>
//         <Typography variant="subtitle1" gutterBottom>
//           Current Balance: ${balance.toFixed(2)}
//         </Typography>
//         {selectedHorseCost > 0 && (
//           <Typography variant="subtitle1" gutterBottom>
//             Cost for Selected Horse: ${selectedHorseCost.toFixed(2)}
//           </Typography>
//         )}
//         {/* Add a logout button */}
//         <Button onClick={logout} variant="contained" color="secondary" sx={{ mt: 2 }}>
//           Logout
//         </Button>

//         <Calendar onChange={handleDateChange} value={date} />

//         <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
//           Available Slots for {date.toDateString()}
//         </Typography>
//         <List>
//           {slots.map((slot) => (
//             <ListItem
//               button
//               key={slot.time}
//               disabled={slot.availableHorses.length === 0}
//               onClick={() => handleSlotClick(slot)}
//             >
//               <ListItemText
//                 primary={formatTime(slot.time)}
//                 secondary={`Available Horses: ${slot.availableHorses.length}`}
//               />
//             </ListItem>
//           ))}
//         </List>

//         {/* Reservation Dialog */}
//         <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
//           <DialogTitle>Confirm Reservation</DialogTitle>
//           <DialogContent>
//             <Typography>
//               Date: {date.toDateString()}
//               <br />
//               Time: {formatTime(selectedSlot?.time)}
//             </Typography>
//             <FormControl fullWidth style={{ marginTop: '20px' }}>
//               <InputLabel>Select Horse</InputLabel>
//               <Select
//                 value={selectedHorse}
//                 onChange={handleHorseChange}
//               >
//                 {availableHorses.map((horse) => (
//                   <MenuItem key={horse.HorseID} value={horse.HorseID}>
//                     {horse.HorseName}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//             {selectedHorseCost > 0 && (
//               <Typography variant="subtitle1" gutterBottom>
//                 Cost for Selected Horse: ${selectedHorseCost.toFixed(2)}
//               </Typography>
//             )}
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
//             <Button onClick={handleReserve} variant="contained" color="primary">
//               Reserve
//             </Button>
//           </DialogActions>
//         </Dialog>
//       </Paper>
//     </Container>
//   );
// }

// export default ReservationPage;





// client/src/pages/ReservationPage.js

// client/src/pages/ReservationPage.js

import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Card,
  CardContent,
  CardActions,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Paper,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material';
import { getHorses, getAvailableSlots, makeReservation, getBalance } from '../services/reservationService';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { format, addMinutes } from 'date-fns';

function ReservationPage({ logout }) {
  // State variables
  const [open, setOpen] = useState(false); // For reservation dialog
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' }); // For notifications
  const [loading, setLoading] = useState(false); // For reservation processing

  const [horses, setHorses] = useState([]);
  const [selectedHorse, setSelectedHorse] = useState(null);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [slots, setSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);

  const [balance, setBalance] = useState(0);

  // Fetch available horses and user balance on component mount
  useEffect(() => {
    fetchHorses();
    fetchBalance();
  }, []);

  // Fetch slots when horse or date changes
  useEffect(() => {
    if (selectedHorse && selectedDate) {
      fetchSlots(selectedHorse.HorseID, format(selectedDate, 'yyyy-MM-dd'));
    }
  }, [selectedHorse, selectedDate]);

  // Function to fetch available horses
  const fetchHorses = async () => {
    try {
      const response = await getHorses();
      setHorses(response.data);
    } catch (error) {
      console.error('Error fetching horses:', error);
      showSnackbar('Failed to fetch horses.', 'error');
    }
  };

  // Function to fetch user balance
  const fetchBalance = async () => {
    try {
      const response = await getBalance();
      setBalance(response.data.balance);
    } catch (error) {
      console.error('Error fetching balance:', error);
      showSnackbar('Failed to fetch balance.', 'error');
    }
  };

  // Function to fetch available slots
  const fetchSlots = async (horseId, date) => {
    try {
      const response = await getAvailableSlots(horseId, date);
      setSlots(response.data);
    } catch (error) {
      console.error('Error fetching slots:', error);
      showSnackbar('Failed to fetch slots.', 'error');
    }
  };

  // Handle reservation dialog open
  const handleOpen = () => {
    setOpen(true);
  };

  // Handle reservation dialog close
  const handleClose = () => {
    setOpen(false);
    setSelectedHorse(null);
    setSelectedDate(new Date());
    setSelectedSlot(null);
  };

  // Handle horse selection
  const handleSelectHorse = (horse) => {
    setSelectedHorse(horse);
    setSelectedSlot(null); // Reset selected slot when horse changes
  };

  // Handle date change
  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedSlot(null); // Reset selected slot when date changes
  };

  // Handle slot selection
  const handleSelectSlot = (slot) => {
    if (slot.status === 'booked') return; // Prevent selecting booked slots
    setSelectedSlot(slot);
  };

  // Handle making a reservation
  const handleReserve = async () => {
    if (!selectedHorse || !selectedSlot) {
      showSnackbar('Please select a horse and a slot.', 'warning');
      return;
    }

    // Prepare reservation data
    const reservationData = {
      date: format(selectedDate, 'yyyy-MM-dd'),
      time: selectedSlot.time, // Assuming time is in 'HH:mm:ss' format
      horseId: selectedHorse.HorseID,
    };

    // Check balance
    if (balance < selectedHorse.Cost) {
      showSnackbar('Insufficient balance to make this reservation.', 'error');
      return;
    }

    setLoading(true); // Start loading

    try {
      await makeReservation(reservationData);
      showSnackbar('Reservation successful!', 'success');
      handleClose();
      fetchBalance();
      fetchSlots(selectedHorse.HorseID, format(selectedDate, 'yyyy-MM-dd'));
    } catch (error) {
      console.error('Error making reservation:', error);
      const errorMsg = error.response?.data || 'Failed to make reservation.';
      showSnackbar(errorMsg, 'error');
    } finally {
      setLoading(false); // End loading
    }
  };

  // Function to display snackbar notifications
  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  // Handle snackbar close
  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  // Generate time slots between 10 AM and midnight, each 40 minutes long
  const generateTimeSlots = () => {
    const start = new Date(selectedDate);
    start.setHours(10, 0, 0, 0); // 10:00 AM

    const end = new Date(selectedDate);
    end.setHours(24, 0, 0, 0); // Midnight

    const slotsArray = [];
    let current = start;

    while (current < end) {
      const slotEnd = addMinutes(current, 40);
      slotsArray.push({
        time: format(current, 'HH:mm:ss'),
        endTime: format(slotEnd, 'HH:mm:ss'),
        status: 'available', // 'available' or 'booked'
      });
      current = addMinutes(slotEnd,5);
    }

    return slotsArray;
  };

  // Determine slot status based on fetched slots
  const determineSlotStatus = () => {
    const generatedSlots = generateTimeSlots();

    // Create a map for quick lookup of booked slots
    const bookedSlotsMap = new Map();
    slots.forEach((slot) => {
      bookedSlotsMap.set(slot.time, 'booked');
    });

    // Assign status to each generated slot
    return generatedSlots.map((slot) => ({
      ...slot,
      status:
        bookedSlotsMap.get(slot.time) ||
        (selectedSlot && selectedSlot.time === slot.time
          ? 'selected'
          : 'available'),
    }));
  };

  return (
    <Container>
      <Typography variant="h4" align="center" gutterBottom sx={{ mt: 4 }}>
        Horse Ride Reservation
      </Typography>
      <Grid container justifyContent="center" sx={{ mt: 2 }}>
        <Button variant="contained" color="primary" onClick={handleOpen}>
          Make a Reservation
        </Button>
      </Grid>

      {/* Reservation Dialog */}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Make a Reservation</DialogTitle>
        <DialogContent>
          {/* Step 1: Select a Horse */}
          {!selectedHorse && (
            <>
              <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                Select a Horse
              </Typography>
              <Grid container spacing={2}>
                {horses.map((horse) => (
                  <Grid item xs={12} sm={6} md={4} key={horse.HorseID}>
                    <Card
                      variant="outlined"
                      sx={{
                        cursor: 'pointer',
                        borderColor: selectedHorse?.HorseID === horse.HorseID ? 'primary.main' : 'grey.300',
                      }}
                      onClick={() => handleSelectHorse(horse)}
                    >
                      <CardContent>
                        <Typography variant="h6">{horse.HorseName}</Typography>
                        <Typography variant="body2">Cost: ${horse.Cost.toFixed(2)}</Typography>
                      </CardContent>
                      <CardActions>
                        <Button size="small" variant="contained" color="primary">
                          Select
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </>
          )}

          {/* Step 2: Select a Date */}
          {selectedHorse && (
            <>
              <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                Selected Horse: {selectedHorse.HorseName}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6">Choose a Date:</Typography>
                  <Calendar onChange={handleDateChange} value={selectedDate} />
                </Grid>
              </Grid>
            </>
          )}

          {/* Step 3: Select a Slot */}
          {selectedHorse && selectedDate && (
            <>
              <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
                Available Slots for {format(selectedDate, 'MMMM dd, yyyy')}
              </Typography>
              <Paper sx={{ mt: 2, maxHeight: 400, overflow: 'auto' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Time Slot</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {determineSlotStatus().map((slot) => (
                      <TableRow
                        key={slot.time}
                        sx={{
                          backgroundColor:
                            slot.status === 'booked'
                              ? 'error.light'
                              : slot.status === 'selected'
                              ? 'success.light'
                              : 'background.paper',
                          cursor: slot.status === 'available' ? 'pointer' : 'not-allowed',
                        }}
                        onClick={() => handleSelectSlot(slot)}
                      >
                        <TableCell align="center">
                          {format(new Date(`1970-01-01T${slot.time}`), 'hh:mm a')} -{' '}
                          {format(new Date(`1970-01-01T${slot.endTime}`), 'hh:mm a')}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {selectedHorse && selectedDate && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleReserve}
              disabled={!selectedSlot || loading}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Reserve'}
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default ReservationPage;

// client/src/pages/LoginPage.js
import React, { useState } from 'react';
import { login } from '../services/authService';
import { Container, TextField, Button, Typography, Paper,  Avatar} from '@mui/material';
import { makeStyles } from '@mui/styles';

// const useStyles = makeStyles((theme) => ({
//   paper: {
//     marginTop: theme.spacing(8),
//     padding: theme.spacing(4),
//     textAlign: 'center',
//   },
//   avatar: {
//     margin: '0 auto',
//     width: '75%',
//     height: 'auto',
//     borderRadius: '80%',
//   },
// }));

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(4),
    textAlign: 'center',
  },
  avatar: {
    margin: '0 auto',
    width: '75%',
    height: 'auto',
    borderRadius: '80%',
  },
  root: {
    position: 'relative',
    flexGrow: 1,
    minHeight: '100vh',
    backgroundImage: 'url(/images/horsesclub.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: 50,
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.3)', // Dark overlay
      zIndex: 1,
    },
  },
  content: {
    position: 'relative',
    zIndex: 2,
  },
  card: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent white
    color: '#000',
  },
  icon: {
    fontSize: 48,
    marginBottom: theme.spacing(1),
  },
  title: {
    fontWeight: 'bold',
    color: '#000',
  },
}));











function LoginPage({ setToken }) {
  const [mobileNumber, setMobileNumber] = useState('');
  const [password, setPassword] = useState('');
  const classes = useStyles();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log("test123");
      const response = await login(mobileNumber, password);
      setToken(response.data.token);
    } catch(err) {
      alert('Login failed');
      console.error(err); // Add this line to see detailed error

    }
  };


  
  

  return (
    <Container maxWidth="xs">
      <Paper elevation={3} sx={{ mt: 8, p: 4 }}>
      <Avatar
          alt="Logo"
          src='../logo512.png'
          className={classes.avatar}
          variant="square"
        />
        <Typography variant="h5" align="center" gutterBottom>
          User Login
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Mobile Number"
            variant="outlined" // Added variant
            fullWidth
            margin="normal"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            required
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined" // Added variant
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <Button type="submit" variant="contained" fullWidth sx={{ mt: 2 }}>
            Login
          </Button>
        </form>
      </Paper>
    </Container>
  );
}

export default LoginPage;

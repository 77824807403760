// client/src/services/authService.js

import api from './api';

export const login = (mobileNumber, password) => {
  return api.post('/auth/login', {
    mobileNumber,
    password,
  });

};


export const getHorses = () => {
  return api.get('/user/horses');
};
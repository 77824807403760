// client/src/pages/ReservationFlow.js

import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import HorseSelectionPage from './HorseSelectionPage';
import DateSelectionPage from './DateSelectionPage';
import SlotSelectionPage from './SlotSelectionPage';
import ConfirmationPage from './ConfirmationPage'; // Optional, if needed

function ReservationFlow() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="select-horse" />} />
      <Route path="select-horse" element={<HorseSelectionPage />} />
      <Route path="select-date" element={<DateSelectionPage />} />
      <Route path="select-slot" element={<SlotSelectionPage />} />
      <Route path="confirmation" element={<ConfirmationPage />} />
    </Routes>
  );
}

export default ReservationFlow;

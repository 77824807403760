// client/src/pages/ConfirmationPage.js

import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  Button,
} from '@mui/material';
import { format } from 'date-fns';

function ConfirmationPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { reservation } = location.state || {};

  if (!reservation) {
    navigate('/');
    return null;
  }

  const { date, time, horseId } = reservation;

  const handleBackToHome = () => {
    navigate('/');
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Reservation Confirmed
      </Typography>
      <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Horse ID:</Typography>
          <Typography variant="body1">{horseId}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Date:</Typography>
          <Typography variant="body1">{format(new Date(date), 'MMMM dd, yyyy')}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Time:</Typography>
          <Typography variant="body1">{format(new Date(`1970-01-01T${time}`), 'hh:mm a')}</Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" sx={{ mt: 4 }}>
        <Button variant="contained" color="primary" onClick={handleBackToHome}>
          Back to Home
        </Button>
      </Grid>
    </Container>
  );
}

export default ConfirmationPage;



// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import {
//   Container,
//   Typography,
//   Grid,
//   Card,
//   CardContent,
//   CardActions,
//   Button,
//   CircularProgress,
//   Snackbar,
//   Alert,
//   CardMedia,
// } from '@mui/material';
// import { getHorses } from '../services/reservationService';
// import { getFullImageUrl } from './ShowHorsesPage';

// function HorseSelectionPage() {
//   const navigate = useNavigate();
//   const [horses, setHorses] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [selectedHorse, setSelectedHorse] = useState(null);
//   const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

//   useEffect(() => {
//     fetchHorses();
//   }, []);

//   const fetchHorses = async () => {
//     try {
//       const response = await getHorses();
//       setHorses(response.data);
//     } catch (error) {
//       console.error('Error fetching horses:', error);
//       showSnackbar('Failed to fetch horses.', 'error');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleSelectHorse = (horse) => {
//     setSelectedHorse(horse);
//   };





//   const handleContinue = () => {
//     if (selectedHorse) {
//       // Pass selectedHorse data via state or a global store (e.g., Context, Redux)
//       navigate('/reserve/select-date', { state: { selectedHorse } });
//     } else {
//       showSnackbar('Please select a horse to continue.', 'warning');
//     }
//   };

//   const showSnackbar = (message, severity) => {
//     setSnackbar({ open: true, message, severity });
//   };

//   const handleSnackbarClose = () => {
//     setSnackbar({ ...snackbar, open: false });
//   };

//   return (
//     <Container sx={{ mt: 4 }}>
//       <Typography variant="h4" align="center" gutterBottom>
//         Select a Horse
//       </Typography>
//       {loading ? (
//         <Grid container justifyContent="center" sx={{ mt: 4 }}>
//           <CircularProgress />
//         </Grid>
//       ) : (
//         <Grid container spacing={2}>
//           {horses.map((horse) => (
//             <Grid item xs={12} sm={6} md={4} key={horse.HorseID}>
//               <Card
//                 variant="outlined"
//                 sx={{
//                   cursor: 'pointer',
//                   borderColor: selectedHorse?.HorseID === horse.HorseID ? 'primary.main' : 'grey.300',
//                 }}
//                 onClick={() => handleSelectHorse(horse)}
//               >
//                 {/* Display the horse image */}
//                 {horse.ImageURL && (
//                   <CardMedia
//                     component="img"
//                     height="140"
//                     // image={horse.ImageURL}
//                     image={getFullImageUrl(horse.ImageURL)}//{horse.ImageURL || "/placeholder.jpg"}
//                     alt={horse.HorseName}
//                   />
//                 )}
//                 <CardContent>
//                   <Typography variant="h6">{horse.HorseName}</Typography>
//                   <Typography variant="body2">Cost: ${horse.Cost.toFixed(2)}</Typography>
//                 </CardContent>
//                 <CardActions>
//                   <Button
//                     size="small"
//                     variant={selectedHorse?.HorseID === horse.HorseID ? 'contained' : 'outlined'}
//                     color="primary"
//                     onClick={(e) => {
//                       e.stopPropagation(); // Prevent card click event
//                       handleSelectHorse(horse);
//                     }}
//                   >
//                     {selectedHorse?.HorseID === horse.HorseID ? 'Selected' : 'Select'}
//                   </Button>
//                 </CardActions>
//               </Card>
//             </Grid>
//           ))}
//         </Grid>
//       )}
//       <Grid container justifyContent="center" sx={{ mt: 4 }}>
//         <Button variant="contained" color="primary" onClick={handleContinue}>
//           Continue
//         </Button>
//       </Grid>

//       {/* Snackbar for notifications */}
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={handleSnackbarClose}
//         anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
//       >
//         <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
//           {snackbar.message}
//         </Alert>
//       </Snackbar>
//     </Container>
//   );
// }

// export default HorseSelectionPage;


// client/src/pages/HorseSelectionPage.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  CardMedia,
} from '@mui/material';
import { getHorses } from '../services/reservationService';

function HorseSelectionPage() {
  const navigate = useNavigate();
  const [horses, setHorses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedHorse, setSelectedHorse] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    fetchHorses();
  }, []);

  const fetchHorses = async () => {
    try {
      const response = await getHorses();
      setHorses(response.data);
    } catch (error) {
      console.error('Error fetching horses:', error);
      showSnackbar('Failed to fetch horses.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleSelectHorse = (horse) => {
    setSelectedHorse(horse);
  };

  const handleContinue = () => {
    if (selectedHorse) {
      navigate('/reserve/select-date', { state: { selectedHorse } });
    } else {
      showSnackbar('Please select a horse to continue.', 'warning');
    }
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Select a Horse
      </Typography>
      {loading ? (
        <Grid container justifyContent="center" sx={{ mt: 4 }}>
          <CircularProgress />
        </Grid>
      ) : (
        <Grid container spacing={2}>
          {horses.map((horse) => {
            const isSelected = selectedHorse?.HorseID === horse.HorseID;
            return (
              <Grid item xs={12} sm={6} md={4} key={horse.HorseID}>
                <Card
                  variant="outlined"
                  sx={{
                    cursor: 'pointer',
                    borderColor: isSelected ? 'primary.main' : 'grey.300',
                  }}
                  onClick={() => handleSelectHorse(horse)}
                >
                  {horse.ImageURL && (
                    <CardMedia
                      component="img"
                      height="140"
                      image={horse.ImageURL}
                      alt={horse.HorseName}
                    />
                  )}
                  <CardContent>
                    <Typography variant="h6">{horse.HorseName}</Typography>
                    <Typography variant="body2">Cost: ${horse.Cost.toFixed(2)}</Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      variant={isSelected ? 'contained' : 'outlined'}
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelectHorse(horse);
                      }}
                    >
                      {isSelected ? 'Selected' : 'Select'}
                    </Button>
                    {/* Show "Continue" next to "Selected" if this horse is selected */}
                    {isSelected && (
                      <Button
                        size="small"
                        variant="contained"
                        color="success"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleContinue();
                        }}
                        sx={{ ml: 1 }}
                      >
                        Continue
                      </Button>
                    )}
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default HorseSelectionPage;

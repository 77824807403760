// client/src/pages/DateSelectionPage.js

import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  Button,
  Snackbar,
  Alert,
} from '@mui/material';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { format } from 'date-fns';

function DateSelectionPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedHorse } = location.state || {};

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  if (!selectedHorse) {
    // Redirect to horse selection if no horse is selected
    navigate('/reserve/select-horse');
    return null;
  }

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleContinue = () => {
    if (selectedDate) {
      navigate('/reserve/select-slot', {
        state: { selectedHorse, selectedDate: format(selectedDate, 'yyyy-MM-dd') },
      });
    } else {
      showSnackbar('Please select a date to continue.', 'warning');
    }
  };

  const handleBack = () => {
    navigate('/reserve/select-horse');
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Select a Date
      </Typography>
      <Grid container justifyContent="center" sx={{ mt: 2 }}>
        <Calendar onChange={handleDateChange} value={new Date(selectedDate)} />
      </Grid>
      <Grid container justifyContent="space-between" sx={{ mt: 4 }}>
        <Button variant="outlined" color="primary" onClick={handleBack}>
          Back
        </Button>
        <Button variant="contained" color="primary" onClick={handleContinue}>
          Continue
        </Button>
      </Grid>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default DateSelectionPage;
